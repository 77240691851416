var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-3",attrs:{"title":"Rekap Izin Siswa"}},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12","xl":"6"}},[_c('validation-provider',{attrs:{"name":"academic_year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tahun Akademik","label-for":"academic_year","state":errors.length > 0 ? false : null,"label-cols-md":"4"}},[_c('v-select',{attrs:{"id":"academic_year","reduce":function (academic_year) { return academic_year.id; },"placeholder":"Pilih Tahun Akademik","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listTA,"label":"name"},model:{value:(_vm.dataParent.academic_year),callback:function ($$v) {_vm.$set(_vm.dataParent, "academic_year", $$v)},expression:"dataParent.academic_year"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"semester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Semester","label-for":"semester","state":errors.length > 0 ? false : null,"label-cols-md":"4"}},[_c('v-select',{attrs:{"id":"semester","reduce":function (semester) { return semester.value; },"placeholder":"Pilih Semester","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listSemester,"label":"text"},model:{value:(_vm.dataParent.semester),callback:function ($$v) {_vm.$set(_vm.dataParent, "semester", $$v)},expression:"dataParent.semester"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Periode","label-for":"start_date","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"start_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Periode Awal"},model:{value:(_vm.dataParent.start_date),callback:function ($$v) {_vm.$set(_vm.dataParent, "start_date", $$v)},expression:"dataParent.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","xl":"6"}},[_c('validation-provider',{attrs:{"name":"class_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kelas","label-for":"class_id","state":errors.length > 0 ? false : null,"label-cols-md":"4"}},[_c('v-select',{attrs:{"id":"class_id","reduce":function (class_id) { return class_id.id; },"placeholder":"Pilih Kelas","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listClass,"label":"class_desc"},model:{value:(_vm.dataParent.class_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "class_id", $$v)},expression:"dataParent.class_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"student_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Siswa","label-for":"student_id","state":errors.length > 0 ? false : null,"label-cols-md":"4"}},[_c('v-select',{attrs:{"id":"student_id","reduce":function (student_id) { return student_id.real_id; },"placeholder":"Pilih Siswa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listSiswa,"label":"name"},model:{value:(_vm.dataParent.student_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "student_id", $$v)},expression:"dataParent.student_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jenis Izin","label-for":"type","state":errors.length > 0 ? false : null,"label-cols-md":"4"}},[_c('v-select',{attrs:{"id":"type","reduce":function (type) { return type.id; },"placeholder":"Pilih Jenis Izin","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listAbsent,"label":"absent_type_desc"},model:{value:(_vm.dataParent.type),callback:function ($$v) {_vm.$set(_vm.dataParent, "type", $$v)},expression:"dataParent.type"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.GetAllData}},[_vm._v(" View ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"success","size":"sm"},on:{"click":_vm.ExportExcel}},[_vm._v(" Export ")])],1)],1)],1)],1)],1)],1)],1),_c('b-card',{staticClass:"mb-3",attrs:{"title":"A. Data Siswa"}},[_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',{staticClass:"border-left",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"NISN","label-for":"nisn","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"nisn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-sm",attrs:{"id":"nisn","type":"text","state":errors.length > 0 ? false : null,"placeholder":"NISN"},model:{value:(_vm.dataParent.nisn),callback:function ($$v) {_vm.$set(_vm.dataParent, "nisn", $$v)},expression:"dataParent.nisn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"name","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Nama"},model:{value:(_vm.dataParent.name),callback:function ($$v) {_vm.$set(_vm.dataParent, "name", $$v)},expression:"dataParent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"gender","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"gender","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Jenis kelamin"},model:{value:(_vm.dataParent.gender),callback:function ($$v) {_vm.$set(_vm.dataParent, "gender", $$v)},expression:"dataParent.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Kelas","label-for":"class","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"class","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"class","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Kelas"},model:{value:(_vm.dataParent.class),callback:function ($$v) {_vm.$set(_vm.dataParent, "class", $$v)},expression:"dataParent.class"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Semester","label-for":"semester","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"semester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"semester","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Semester"},model:{value:(_vm.dataParent.semester),callback:function ($$v) {_vm.$set(_vm.dataParent, "semester", $$v)},expression:"dataParent.semester"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-between flex-column",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{attrs:{"src":"https://www.pngitem.com/pimgs/m/78-786293_1240-x-1240-0-avatar-profile-icon-png.png","text":"Devy Ferdiansyah, M. Kom","variant":"light-primary","size":"208px","rounded":""}})],1)])],1)],1),_c('b-card',{staticClass:"mb-3",attrs:{"title":"B. Data Izin"}},[_c('hr'),_c('b-col',{attrs:{"md":"12"}},[_c('table',{staticClass:"table",attrs:{"id":"recaptable"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("Tanggal")]),_c('th',[_vm._v("Jenis Izin")]),_c('th',[_vm._v("Keterangan")]),_c('th',[_vm._v("Jam Mulai")]),_c('th',[_vm._v("Jam Selesai")]),_c('th',[_vm._v("Mata Pelajaran")]),_c('th',[_vm._v("Guru Pengajar")]),_c('th',[_vm._v("Guru Piket")]),_c('th',[_vm._v("Attachment File")])])]),_c('tbody')])])],1),_c('b-card',{staticClass:"mb-3",attrs:{"title":"Total Izin"}},[_c('hr'),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Sakit","label-for":"sakit","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"sakit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sakit","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Sakit"},model:{value:(_vm.dataParent.sakit),callback:function ($$v) {_vm.$set(_vm.dataParent, "sakit", $$v)},expression:"dataParent.sakit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Terlambat","label-for":"terlambat","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"terlambat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"terlambat","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Terlambat"},model:{value:(_vm.dataParent.terlambat),callback:function ($$v) {_vm.$set(_vm.dataParent, "terlambat", $$v)},expression:"dataParent.terlambat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Meninggalkan Sekolah","label-for":"alpha","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"alpha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"alpha","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Meninggalkan Sekolah"},model:{value:(_vm.dataParent.alpha),callback:function ($$v) {_vm.$set(_vm.dataParent, "alpha", $$v)},expression:"dataParent.alpha"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }